import { FILE_TYPES_TO_MIME } from '~/server/files/helpers'

export default (blob: Blob, fileExtension: keyof typeof FILE_TYPES_TO_MIME, dataEntity?: string) => {
  const { $i18n } = useNuxtApp()

  const a = document.createElement('a')

  // Enforce the MIME type explicitly to avoid mobile browser misinterpretation
  const pdfBlob = new Blob([blob], { type: FILE_TYPES_TO_MIME[fileExtension] })
  a.href = URL.createObjectURL(pdfBlob)

  // Create File Name
  const nameParts = [$i18n.t('app.fileTag')]
  if (dataEntity) {
    nameParts.push(dataEntity)
  }
  nameParts.push($i18n.d(new Date(), 'numeric'), useAuth().data.value?.user?.email ?? '')
  a.download = `${nameParts.join('-')}${fileExtension}`
  a.click()

  window.URL.revokeObjectURL(a.href)
  a.remove()
}
